.service-detail-page {
  .service-details {
    .service-image {
      width: 100%;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .service-description {
      .service-title {
        font-weight: 600;
        font-size: 1.8rem;
      }

      .service-price {
        color: #58CAF4;
        font-size: 2.2rem;
        font-weight: 600;
      }
    }

    .provided-by-name {
      color: #0D59A8;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .book-service {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
