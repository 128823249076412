.speciality .bg-speciality {
    background-color: #E9ECF4;
}

.speciality .breadcrumb-item {
    font-size: 18px;
}

.speciality .breadcrumb-item a {
    color: #0259A7;
    text-decoration: none;
}

.speciality .breadcrumb-item a:hover {
    color: #0259A7;
    opacity: 0.8;
}

.speciality .breadcrumb-item {
    padding-bottom: 2rem;
}

.speciality h1, .speciality h3 {
    font-weight: 600;
}
