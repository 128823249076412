.cart-buttons button {
    padding: 6px 12px;
    color: white;
}

.cart-buttons button:hover {
    opacity: 0.8;
}

.cart-buttons .clear-all {
    border: none;
    background: #DC3545;
}

.cart-buttons .continue {
    border: none;
    background-color: #559EFC;
}

@media screen and (max-width: 768px) {
    .wish-item-container, .wish-item-container h5 {
        font-size: 14px;
    }

    .add-to-cart, .delete {
        width: 100%;
    }


    .wish-item-container button {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 2px;
        padding: 6px 4px;
        font-size: 11px;
    }
}
