.services {
    background-image: linear-gradient(45deg, #57c7f7, #2f89fc);
}

.icon-container {
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%
}

.icon-img {
    width: 50px !important;
    height: 50px;
}

/* @media only screen and (max-width: 767px) {
    .service-container{
        margin-top: -80px;

    }
} */

.service-container {
    background-color: #0259A7;
    /* background-image: url('../images/advert.png'); */
}

.service-container {
    border-radius: 18px;
}

.service-image {
    width: 100px;
}

.service-container .d-flex {
    text-align: justify;
}

@media only screen and (min-width: 576px) {
    .service-image {
        width: 80px;
    }
}

@media only screen and (min-width: 768px) {
    .service-image {
        width: 125px;
    }

    .service-container .d-flex {
        text-align: justify;
    }
}

@media only screen and (min-width: 1000px) {
    .service-image {
        width: 90px;
        padding-right: 0;
    }

    .service-container .d-flex {
        text-align: justify;
    }
}

@media only screen and (min-width: 1400px) {
    .service-image {
        width: 120px;
    }

    .service-container .d-flex {
        text-align: justify;
    }
}
