.gd-store {
  .breadcrumb {
    font-size: 1.1rem;

    p {
      font-size: 1.1rem;
      color: #0D59A8;
      font-weight: 500;
    }

  }
}
