body {
    text-align: justify;
}

.btn-primary {
    border-color: #0259A7;
    background-color: #0259A7;
    border-radius: 56px;
    padding: 10px 18px;
}

.btn-primary:hover {
    border-color: #0674d3;
    background-color: #0674d3;
}

.item {
    position: relative;
}

.slider {
    width: 100%;
    aspect-ratio: 1.8/1;
    /* height:500px; */
    object-fit: cover;
}

.overlay-slider {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(273.86deg, rgba(87, 199, 243, 0.12) -8.68%, rgba(0, 0, 0, 0.50) 104.72%);
}

.description h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 1px;
}

.description p {
    font-weight: 500;
    font-size: 24px;
}

.description {
    /* position:absolute;
    bottom:140px;
    left:10px; */
}

.side-image {
    position: absolute;
    max-width: 600px;
    max-height: 800px;
    right: 0;
    bottom: 0;
}

.sliders .slick-slide img {
    display: inline;
}

.image {
    display: none;
}

.slide-skeleton {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 2.42/1 !important;
}

@media only screen and (min-width: 200px) and (min-width: 300px) {

    .description h1 {
        font-size: 35px;
    }
}

@media only screen and (min-width: 768px) {
    .slider {
        /* height:500px; */
        aspect-ratio: 2.42/1;
        object-fit: cover;
    }

    .description {
        /* position:absolute;
        bottom:120px;
        left:60px; */
    }

    .overlay {
        position: absolute;
        top: 0;
        height: 500px;
        width: 100%;
        background: linear-gradient(273.86deg, rgba(87, 199, 243, 0.12) -8.68%, rgba(0, 0, 0, 0.68) 104.72%);
    }

    .description h1 {
        font-size: 60px;
    }
}

@media only screen and (min-width: 1000px) {
    .slider {
        /* height:600px; */
        aspect-ratio: 2.42/1;
        object-fit: cover;
    }

    .description {
        /* position:absolute;
        bottom:180px;
        left:60px; */
    }

    .image {
        display: block;
    }

    .side-image {
        position: absolute;
        max-width: 450px;
        max-height: 800px;
        right: 0;
        /* top: 80px; */
        bottom: 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        height: 600px;
        width: 100%;
        background: linear-gradient(273.86deg, rgba(87, 199, 243, 0.12) -8.68%, rgba(0, 0, 0, 0.68) 104.72%);
    }
}

@media only screen and (min-width: 1400px) {
    .slider {
        /* height:700px; */
        aspect-ratio: 2.42/1;
        object-fit: cover;
    }

    .description {
        /* position:absolute;
        bottom:230px;
        left:210px; */
    }

    .side-image {
        position: absolute;
        max-width: 500px;
        max-height: 800px;
        right: 0;
        /* top: 120px; */
        bottom: 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        height: 700px;
        width: 100%;
        background: linear-gradient(273.86deg, rgba(87, 199, 243, 0.12) -8.68%, rgba(0, 0, 0, 0.68) 104.72%);
    }

    .description h1 {
        font-size: 75px;
    }
}
