.section-vendors {
  .vendor-list {
    .flex-wrap-items {
      box-sizing: border-box;
      width: 33%;
      padding: 0 1%;

      @media screen and (min-width: 1200px) {
        width: 14.26%;
        padding: 0 1%;
      }

      @media screen and (min-width: 992px) and (max-width: 1200px) {
        width: 16.66%;
        padding: 0 1%;
      }

      @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 25%;
        padding: 0 1%;
      }

      @media screen and (min-width: 576px) and (max-width: 768px) {

      }
    }

    .vendor-card {
      width: 100%;
      aspect-ratio: 1/1.3;
      box-shadow: 0 2px 10px #D2D2D2;

      .card-header {
        height: 70%;
        margin: 0;
        padding: 0;
        background: inherit;

        .img-div {
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }

      .card-body {
        height: 30%;
        padding-top: 4px;
        padding-bottom: 4px;

        @media screen and (max-width: 425px) {
          max-height: 50%;

          .vendor-card-name h5 {
            font-size: 13px !important;
            font-weight: bold;
          }

        }
      }
    }
  }
}

.flex-wrap-items {
  box-sizing: border-box;
  width: 100%;
  padding: 0 1%;

  @media screen and (min-width: 1200px) {
    width: 20%;
    padding: 0 1%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 33%;
    padding: 0 1%;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 50%;
    padding: 0 1%;
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {

  }
}
