.react-image-magnify {
    position: relative;
    z-index: 1
}

.image-magnifier {
    width: 300px;
    height: 310px;
    position: relative;
}

.image-container {
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
}

.image-container .image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: black;
    opacity: 0;
}


.image-magnifier .image-container .image-div {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-div img {
    width: 100%;
    object-fit: fill;
    cursor: crosshair;
}

.image-magnifier .zoom-overlay {
    position: absolute;
    display: none;
    z-index: 9999;
    background-color: #BFE6FF;
    opacity: 0.7;
    cursor: zoom-in !important;
}

.image-magnifier .image-zoom-content {
    position: absolute;
    left: 106%;
    top: 0;
    z-index: 999;
    overflow: hidden;
    border: 2px solid #D4D4D4;
}

.image-zoom-content img {
    position: absolute;
}

.image-magnifier:hover .image-container .image-overlay {
    opacity: 0.4;
}

.image-magnifier:hover .zoom-overlay {
    display: block;
    /* background-color: transparent; */
}

.long-div {
    height: 1000px;
    width: 1000px;
    background: blue;
}
