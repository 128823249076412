.order-verifying {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vW;
  height: 100vH;
  background: black;
  opacity: 0.7;
  z-index: 9999;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
