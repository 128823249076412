$small: 576px;
$medium: 768px;
$large: 992px;


.prod-card {
  width: 250px;
}

.prod-card .img-div {
  width: 100%;
  height: 300px;
}

.prod-card img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.toggle-filter-side {
  height: 40px;
  width: 40px;
  background-color: #0259a7;
  display: none;
}

/* .category-prod-container {

} */

.side-bar-filter .filter-list {
  background-color: #ffffff;
  /* box-shadow: 0 0 20px #E3E3E3 !important; */
  padding: 10px;
}

.side-bar-toggler {
  display: none;
}

.filter-list ul li {
  font-weight: 501;
  border: none !important;
  cursor: pointer;
}

.filter-list ul li:hover {
  color: #57c7f3;
}

.search-bar {
  position: relative;
}

.search-dropdown {
  display: block;
  position: absolute;
  width: 100%;
  height: 18rem;
  background: white;
  z-index: 5;
  top: 120%;
  box-shadow: 0 1px 5px black;
  overflow-y: scroll;
}

.show {
  display: block;
}

.top-ribbon {
  height: 40px;
  background-color: #ffff;
  border: 1px #e8e8e8;
  box-shadow: 0 0 20px #e3e3e3 !important;
  line-height: 40px;
}

.min-max-div .price-input input {
  width: 80px;
}

.prod-container {
  aspect-ratio: 1/1.5;
}

.product-card {
  // height: 100%;
  width: 100%;
  border: 2px solid #f9fafb;
  // box-shadow: 0 2px 10px #D2D2D2;
  position: relative;

  .stock-info {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    display: none;
  }
}

.stock-0 {

  &:hover {
    // pointer-events: none;
    .stock-info {
      display: block;
    }

    .price-text, .prod-card-rating-star, .prod-card-name, .blue-highlight-txt {
      color: gray;
    }

    .overlay-card, .action-bar {
      display: none !important;
    }

    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }


}

.product-card .img-div,
.product-card .overlay-card {
  // height: 65%;
  width: 100%;
  aspect-ratio: 1/1;
}

.product-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prod-card-body {
  // height: 35%;
  /* background-color:#F9FAFB !important; */
}

.prod-card-rating-star {
  font-size: inherit !important;
}

.price-text {
  color: #0259a7;
}

.original-price {
  font-size: 15px !important;
  // font-weight: normal !important;
  // line-height: 3em !important;
}

.overlay-card {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  background-color: black;
  transition: all 300ms;
}

.action-bar {
  display: none;
  position: absolute;
  width: 90%;
  top: 28%;
  left: 5%;
  margin: auto;
  height: 40px;
  line-height: 40px;
  color: white;
  transition: all 300ms;
  opacity: 0;
}

.ic-con {
  transition: all 200ms;
}

.ic-con:hover {
  color: rgb(198, 233, 250);
  font-size: 1.4rem;
}

.product-card:hover {
  cursor: pointer;
  box-shadow: 0 2px 10px #D2D2D2;
}

.product-card:hover .overlay-card {
  display: block;
  opacity: 0.65;
}

.product-card:hover > .action-bar {
  display: block;
  opacity: 1;
}

.prod-card-category {
  height: 1.5em
}

.prod-card-name {
  box-sizing: border-box;
  height: 2.2em;
  line-height: 1.1em;
  font-size: 16px !important;
  color: #10AAF1;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.prod-card-price {
  height: 1.5em;
  line-height: 1.5em;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 4px;
  margin-bottom: 8px;
}

.prod-card-price span {
  // font-family: Roboto-Regular !important;
  font-size: 1.2em;
  font-weight: bold;
}

.prod-card-rating {
  height: 1.5em;
  box-sizing: border-box;
  overflow: hidden;
}

.icon-links {
  margin-right: 20px;
  cursor: pointer;
}

.light-black-text {
  color: #bdbdbd;
}

.remove-txt-decor {
  text-decoration: none;
  color: inherit;
}

.remove-txt-decor:hover {
  color: inherit;
}

.blue-highlight-txt {
  color: #1ab1d6;
}

.business-color-1 {
  color: #0d59a8;
}

.black-variation-1 {
  color: #1e2734;
}

.close-side-bar {
  display: none !important;
}

.active-filters {
  overflow-x: scroll;
}

.prod-top-bar {
  .filter-toggle-mobile {
    display: none !important;
    border: 1px solid #5EC8EF;
    padding: 5px 10px;
    border-radius: 10px;
    color: #5EC8EF;

    @media screen and (max-width: $large) {
      //do Smth
      display: flex !important;
    }
  }

  @media screen and (max-width: $large) {
    .prod-top-bar-sort {
      width: 100%;
      margin-bottom: 10px;
      padding-top: 10px;
      border-top: 1px solid #cfcfcf;
    }
  }
}

@media screen and (max-width: 1500px) {
  // .res-text {
  //   font-size: 1.1rem;
  // }
}

@media screen and (max-width: 1380px) {
  // .res-text {
  //   font-size: 0.9rem;
  // }
}

@media screen and (max-width: 990px) {
  .res-text {
    font-size: 1.1rem;
  }

  .side-bar-filter {
    display: none;
    /* position: fixed; */
    z-index: 99;
    width: 300px;
    padding: 5px 20px;
    background: white;
    box-shadow: 0 0 5px black;
    overflow-y: scroll;
  }

  .close-side-bar {
    display: block !important;
  }

  .side-bar-toggler {
    display: block;
    width: 40px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ced4da;
    text-align: center;
    margin-bottom: 10px;
    z-index: 1;
  }

  .side-bar-expand {
    position: fixed;
    z-index: 9999;
    height: 100vh !important;
    top: 80px;
    left: 0;
    overflow-y: scroll;
    padding-bottom: 80px;
  }

  .collapse-filter {
    display: none;
  }

  .expand-filter {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .res-text {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 426px) {
  .res-text {
    font-size: 0.9rem;
  }

  .sort-label {
    display: none !important;
  }


}

@media screen and (max-width: 400px) {

  .product-card .img-div,
  .product-card .overlay-card {
    height: 55%;
  }
}
