.service-page {
  .inner-package {
    background: linear-gradient(159deg, rgba(255, 255, 255, 0.9) 0%, rgba(198, 223, 246, 0.9) 91%), url(https://en.saicmaxus.com/uploads/month_2003/2020030207202324124.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .service-page-container {
    &.service-background {
      background: url("../../../../src/images/pattern.png");
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: 120px;
      padding-top: 60px;
      overflow: hidden;
      background-position: left bottom;

      // .service-row {

      // }

      .service-title {
        h1 {
          font-weight: 700;
          line-height: 1em;
          margin-bottom: 20px;
        }
      }

      .service-icon {
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      // .service-description {
      //     min-height: 380px;
      // }

      .service-image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;


        img {
          width: 70%;
          aspect-ratio: 1/1.4;
          object-fit: cover;
          border-top-left-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }
  }
}
