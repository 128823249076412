@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navigation {
    box-shadow: 11.0543px 16.5815px 33.163px rgba(0, 0, 0, 0.05);
}

.navigation .nav-item a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    /* margin-right: 1rem; */
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.241037px;

}

.navigation .nav-item a:hover {
    color: #2f89fc;
}

.navigation .nav-item.active a {
    color: #2f89fc;

}

.logo {
    height: 4rem;
    max-width: fit-content;
}

/* .nav-list{
    justify-content: end;
    padding-left: 1rem;

} */

/* .nav-items{
    justify-content: space-around !important;
    margin-right: 0.5rem;
    align-items: center;
} */
/* .act
    color:rgb(82, 168, 235) !important;
} */

.Appointment {
    margin-right: 1rem;
    border-radius: 7rem;
    margin-bottom: 0.5rem !important;
}

.login-btn {
    border-radius: 7rem;
    color: white !important;
}

.login-btn:hover {
    color: white !important;
}

/* .navigation .dropdown-menu{
    padding:0;
}
.navigation .dropdown-item{
    padding:10px;
} */

/* .download-btn{
    color: #000;
}
.download-btn:hover{
    color:white !important;
} */


/* @media screen and (min-device-width: 320px) and (max-device-width: 768px) {
    .nav-list{
    padding-left : 1rem;
    margin-left: none !important;
    max-height:55rem ;
    }
    .nav-items{
        align-items: start;
        height: 20rem;
    }
    .border-rectangle{
        display: none ;
    }

} */

.btn-outline-primary {
    border-color: #0259A7 !important;
}

.btn-outline-primary:hover {
    background-color: #0259A7 !important;
    border-color: #0259A7 !important;
    color: white !important;
}

.login-border {
    border-right: 4px solid #c6dff6;
}

@media screen and (max-device-width: 1000px) {
    .login-border {
        display: none;
    }

    .nav-login {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.navigation .dropdown-item {
    text-transform: lowercase;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.navigation .dropdown-item:first-letter {
    text-transform: uppercase;
}
