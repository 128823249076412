.notification-page {
  .card-header {
    margin: 0;
    padding: 0;

    button {
      padding: 20px 0;
      margin: 0 30px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .card-body {
    // font-size: 1.1rem;
    padding-bottom: 0;

    .MuiBox-root {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .notifications-list {
    padding: 40px 0;
    border-bottom: 2px solid #F5F5F5;
    font-size: 1.1rem;

    &:last-child {
      border-bottom: none;
    }

    .notification-img {
      width: 100%;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
