.nav-bar-vendors {
  .card {
    border: none;
  }

  .card-header {
    background-color: #0d59a8 !important;
    color: white;
    margin-top: 10px;

    ul {
      li {
        text-decoration: none;
        color: white;
        width: 100%;
        text-align: center;
      }

      li {
        cursor: pointer;

        &:hover {
          text-decoration: underline;

          .drop-down-category {
            display: block;
            @media screen and (max-device-width: 700px) {
              width: 200%;
            }

            li {
              a {
                @media screen and (max-device-width: 500px) {
                  font-size: 12px !important;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-device-width: 500px) {
      font-size: 13px !important;
    }
  }

  .nav-item {
    position: relative;

    .drop-down-category {
      display: none;
      position: absolute;
      z-index: 999 !important;
      width: 100%;
      color: black;
      padding: 10px 15px;

      ul {
        padding: 0;
        width: 100%;
        background-color: #fff;
        border: 2px solid #d2d2d2 !important;
        border-radius: 6px;
        list-style: none;

        a {
          text-decoration: none;
          color: black;
          width: 100%;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}


