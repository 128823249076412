.become-member {
  background: #303639;

  .row > .boot-column {
    border-right: 1px solid white;
  }

  .become-member-container {
    // padding: 0 50px;
    text-align: center;

    .become-member-title {
      color: white;
      font-size: 30px;
      font-weight: 700;
    }
  }
}
