* {
    margin: 0;
    padding: 0;
}

.termsandcondition {
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 50px;

}

.termsandcondition h3 {
    font-weight: bolder;

    font-size: 15px;
    border-bottom: 1px solid black;
}

.termsandcondition h5 {
    font-weight: bold;
    font-size: 15px;
}

.termsandcondition h2 {
    font-size: 20px;
    font-weight: bold;
}

.last {
    padding-bottom: 50px;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 30px;
    z-index: 99;
    font-size: 22px;
    border: none;
    outline: none;
    background-color: gray;
    color: white;
    cursor: pointer;

}

#myBtn:hover {
    background-color: #555;
}
