.packages {
    background: linear-gradient(120deg, rgba(87, 199, 243) 40%, rgba(192, 223, 246, 0.6));
}

/* .packages .slick-slide div {
    width: 100%;
} */

.packages h1 {
    font-weight: 700;
}

.packages h2 {
    font-weight: 600;
}

.packages .heading h1 {
    letter-spacing: 1px;
}

.packages p {
    font-weight: 600;
    opacity: 0.7;
}

.packages .card {
    border-radius: 8px;
}

.packages .card-header {
    border-radius: 6px;
    padding-top: 100px;
}

.price-span, .packages h4 {
    color: #000;
    opacity: 0.3;
    font-weight: 600;
}

.price-span {
    margin-left: 8px;
    font-size: 1.1rem;
}

.packages .card-body p {
    font-size: 15px;
}

.packages .card-body ul {
    font-size: 15px;
}

.packages .card-body li {
    font-size: 15px;
}

.packages .d-grid a {
    font-size: 22px;
    font-weight: 600;
}

.name-wrapper {
    position: relative;
    margin-top: 50px;
    height: 100%;
}

.name {
    width: 210px;
    border-radius: 7px;
    z-index: 1;
    position: absolute;
    top: -40px;
    left: 44px;
    padding-left: 20px;
    padding-right: 20px;
}

.name h3 {
    color: #fff;
    line-height: 100%;
    font-size: 28px;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 105px solid transparent;
    border-right: 105px solid transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1;
    position: absolute;
    top: 42px;
    left: 44px;
}

/* .customized .triangle {
    position: absolute;
    top: 52px;
    left:44px;
}

.vip .triangle {
    position: absolute;
    top: 38px;
    left:44px;
} */
.packages .slick-track {
    display: flex;
}

.packages .slick-track .slick-slide {
    display: flex;

    height: auto;
    overflow: visible;
    padding-top: 5px;
    padding-bottom: 70px;
}

.packages .slick-track .slick-slide > :first-child {
    flex-grow: 1;
}

.silver .card-header {
    background-color: #E6E6E6;
    border: 1px solid #E6E6E6;
}

.golden .card-header {
    background-color: #FCEDCD;
    border: 1px solid #FCEDCD;
}

.platinum .card-header {
    background-color: #FFCCE0;
    border: 1px solid #FFCCE0;
}

.customized .card-header {
    background-color: #E5F0DB;
    border: 1px solid #E5F0DB;
}

.vip .card-header {
    background-color: #d7caf3;
    border: 1px solid #d7caf3;
}

.corporate .card-header {
    background-color: #8dd6d5;
    border: 1px solid #8dd6d5;
}

.silver .name {
    background-color: #333;
}

.golden .name {
    background-color: #F6AA0D;
}

.platinum .name {
    background-color: #E5005B;
}

.customized .name {
    background-color: #75A446;
}

.vip .name {
    background-color: #8756f0;
}

.corporate .name {
    background-color: #028482;
}

/* .customized .name h3{
   font-size: 22px;
}

.vip .name h3{
    font-size:26px
} */

.silver .triangle {
    border-top: 40px solid #333;
}

.golden .triangle {
    border-top: 40px solid #F6AA0D;
}

.platinum .triangle {
    border-top: 40px solid #E5005B;
}

.customized .triangle {
    border-top: 40px solid #75A446;
}

.vip .triangle {
    border-top: 40px solid #8756f0;
}

.corporate .triangle {
    border-top: 40px solid #028482;
}

.packages .slick-next {
    margin-right: 17px;
}

.packages .slick-prev {
    margin-left: 17px;
}

.pg-description {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    /* number of lines to show */
    line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.package-page .name-wrapper {
    height: auto !important;
}

@media only screen and (min-width: 350px) {
    .name {
        position: absolute;
        top: -40px;
        left: 70px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 70px;
    }

    /* .customized .triangle{
        position: absolute;
        top: 52px;
        left:65px;
    }
    .vip .triangle {
        position: absolute;
        top: 38px;
        left:65px;
    } */
    .packages .slick-next {
        margin-right: 15px;
    }

    .packages .slick-prev {
        margin-left: 15px;
    }
}

@media only screen and (min-width: 400px) {
    .name {
        position: absolute;
        top: -40px;
        left: 95px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 95px;
    }

    /* .customized .triangle{
        position: absolute;
        top: 52px;
        left:95px;
    }
    .vip .triangle {
        position: absolute;
        top: 38px;
        left:95px;
    } */
    .packages .slick-next {
        margin-right: 15px;
    }

    .packages .slick-prev {
        margin-left: 15px;
    }
}

@media only screen and (min-width: 768px) {
    .name {
        position: absolute;
        top: -40px;
        left: 65px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 65px;
    }

    /* .customized .triangle{
        position: absolute;
        top: 52px;
        left:70px;
    }
    .vip .triangle {
        position: absolute;
        top: 38px;
        left:70px;
    } */
    .packages .slick-next {
        margin-right: 7px;
    }

    .packages .slick-prev {
        margin-left: 7px;
    }

}

@media only screen and (min-width: 1000px) {
    .name {
        position: absolute;
        top: -40px;
        left: 45px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 45px;
    }

    /* .customized .triangle{
        position: absolute;
        top: 53px;
        left:50px;
    }
    .vip .triangle {
        position: absolute;
        top: 38px;
        left:50px;
    } */
    .packages .slick-next {
        margin-right: 7px;
    }

    .packages .slick-prev {
        margin-left: 7px;
    }

}

@media only screen and (min-width: 1200px) {
    .name {
        position: absolute;
        top: -40px;
        left: 28px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 28px;
    }

    /* .customized .triangle{
        position: absolute;
        top: 53px;
        left:35px;
    }
    .vip .triangle {
        position: absolute;
        top: 38px;
        left:35px;
    } */
    .packages .slick-next {
        margin-right: 7px;
    }

    .packages .slick-prev {
        margin-left: 7px;
    }

}

@media only screen and (min-width: 1400px) {
    .name {
        position: absolute;
        top: -40px;
        left: 48px;
    }

    .triangle {
        position: absolute;
        top: 42px;
        left: 48px;
    }

    /*
        .customized .triangle{
            position: absolute;
            top: 52px;
            left:59px;
        }

        .vip .triangle {
            position: absolute;
            top: 38px;
            left:58px;
        } */
    .packages .slick-next {
        margin-right: 7px;
    }

    .packages .slick-prev {
        margin-left: 7px;
    }

}
