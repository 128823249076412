.section-brands {
  .brand-list {
    .brand-card {
      aspect-ratio: 1.1/1;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .card-header {
        background: inherit;
        padding: 0;
        margin: 0;
        height: 100%;
        border-bottom: none;

        .img-div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .slick-track,
  .slick-list {
    width: 100%;
    min-width: 100%;
  }
}
