.symptoms-identify {
    background: linear-gradient(120deg, rgba(192, 223, 246, 0.95) 30%, rgba(87, 199, 243));
}

.symptoms-identify .heading p:nth-child(1) {
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: #0259A7;
}

.symptoms-identify .heading p:nth-child(2) {
    font-weight: 400;
    line-height: 100%;
    color: #000000;
}

.btn-outline-light {
    border-width: 2px;
}

/* .speciality-btn{
    border-radius:37px;
    padding:6px 15px;
    font-weight: 600;
} */
.symptom-btn:hover {
    color: #0259A7;
}

.symptom-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.symptom-icon img {
    /* position: absolute;
    left: 25px;
    top:25px; */
    width: 70%;
}

.consult-btn {
    color: #0259A7;
    text-decoration: none;
}

/* .symptoms-identify .slick-slide{
    width:150px !important;
} */
.slick-prev {
    margin-left: 22px;
}

.slick-next {
    margin-right: 25px;
}

.symptom-container {
    margin-bottom: 50px;
}

.icon-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #012443;
}

/* .symptoms-identify .slick-next{
    font-size: 22px;
    border: 3px solid #0259A7;
    border-radius:50%;
} */

.symptoms-identify .slick-arrow {
    z-index: 1;
    padding: 20px;
    background-color: #0D59A8;
    border-radius: 5px;
}

.symptoms-identify .slick-prev:before, .symptoms-identify .slick-next:before {
    color: #fff;
    opacity: 1 !important;
    font-size: 25px;
    line-height: none;
    position: absolute;
    top: 10px;
    right: 7px;
}

.symptoms-identify .slick-disabled:before {
    color: #fff;
    opacity: 0.5 !important;
}
