.image-side {
    background-image: linear-gradient(45deg, #0259A7 0%, #57C7F3 100%);
    height: 100vh;
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    /* border-top-right-radius: 5%;
    border-bottom-right-radius: 5%; */
}

.bg-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* border-top-right-radius: 5%;
    border-bottom-right-radius: 5%; */
}

.image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(45deg, #0259A7 0%, #57C7F3 100%);
    opacity: 0.8;
    /* border-top-right-radius: 5%;
    border-bottom-right-radius: 5%; */
}

.image-side {
    display: none;
}

.login .card {
    box-shadow: 11.0543px 16.5815px 33.163px rgba(2, 89, 167, 0.1);
}

.auth .card-title {
    font-weight: 600;
    line-height: 120%;
    color: #001221;
}

.auth .card p {
    color: rgba(102, 102, 102, 0.8);
    font-size: 17px;
}

.auth .card label {
    font-size: 17px;
    line-height: 120%;
    margin-bottom: 8px;
}

.auth .input-field {
    height: 50px;
    border: 0.906977px solid rgba(102, 102, 102, 0.35);
    border-radius: 10.8837px;
    margin-bottom: 15px;
}

.auth .form-check-input:checked {
    background-color: #111111;
    border-color: #111111;
}

.auth .form-check-input {
    width: 1.2em;
    height: 1.2em;
}

.login a {
    color: #111;
}

.login .forget-psw {
    font-size: 17px;
}

.login a:hover {
    color: #111;
    opacity: 0.8;
}

.login-btn {
    padding: 15px;
}


.login h6 {
    font-size: 15px;
}

.signup-btn {
    text-decoration: none;
    color: #0259A7 !important;
    font-weight: 600;
}

.signup-btn:hover {
    color: #0259A7 !important;
    opacity: 1 !important;
    text-decoration: underline;
}

.login .card, .login-form {
    padding: 1rem;
}

@media only screen and (min-width: 768px) {
    .login .card, .login-form {
        padding: 3rem;
    }

    .forget-psw-container {
        text-align: end;
    }
}

@media only screen and (min-width: 1000px) {
    .image-side {
        display: block;
    }

    .bg-logo img {
        width: 230px;
    }

    .bg-logo {
        position: absolute;
        top: 100px;
        left: 200px;
    }

    .login .card, .login-form {
        padding: 3rem;
    }

    .forget-psw-container {
        text-align: end;
    }
}

@media only screen and (min-width: 1400px) {
    .image-side {
        display: block;
    }

    .bg-logo img {
        width: 300px;
    }

    .bg-logo {
        position: absolute;
        top: 200px;
        left: 250px;
    }

    .login .card, .login-form {
        padding: 3rem;
    }

    .forget-psw-container {
        text-align: end;
    }
}
