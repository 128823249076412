.doctor-prof-page {
  .label-txt {
    font-weight: 700;
  }

  .sub-label-txt {
    font-weight: 600;
  }

  .section-title {
    font-size: 22px;
  }

  .sub-title {
    font-size: 18px;
  }

  .profile-head {
    .profile-image {
      flex-basis: 30%;
      overflow: hidden;

      img {
        width: 90%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .profile-desc {
      .profile-desc-name {
        h2 {
          font-size: 35px;
          font-weight: 700;
          color: #0D59A8;
          text-align: left !important;
        }
      }
    }
  }

  .profile-nav {
    padding: 15px 0;
    border-top: 1px solid #DFE2EA;
    border-bottom: 1px solid #DFE2EA;
    font-size: 18px;

    .profile-nav-item {
      // cursor: pointer;

      // &:hover {
      //     color: #0D59A8;
      // }
    }
  }

  .about-section {

    .about-section-desc {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      a {
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
      }

    }

    .about-section-infos {
      .about-info {
        .about-info-title {
          font-size: 20px;
          margin-bottom: 10px;
        }

        .about-info-detail {
          & > div {
            line-height: 22px;
            font-weight: 600;
          }

          .logo-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              object-fit: cover;
            }
          }

        }

      }
    }
  }

  .review-section {
    .review-header {
      padding: 20px 0;
      border-top: 2px solid #58CAF4;
      border-bottom: 1px solid #DFE2EA;

      .star-ic {
        color: gold;
      }
    }

    .reviews-container {
      border-bottom: 2px solid #58CAF4;
    }

    .review-ribbon {
      border-bottom: 1px solid #DFE2EA;
    }

    .main-reviews {
      border-left: 1px solid #DFE2EA;
    }


    .review-item {
      .review-top {

        .review-user-image {
          flex-basis: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .review-date, .ml-auto {
          margin-left: auto;
        }
      }
    }
  }

  .hospital-locations {

    .hospital-maps {
      width: 100%;
      overflow: hidden;
    }

    .hospital-map {
      width: 100%;
      aspect-ratio: 1/1;
    }
  }
}
