.health-center {
  // background-image:url(../images/advert.png);
  .health-news {
    .custom-slick {
      .slick-slider {
        width: 95%;
        margin: auto;
      }

      .slick-list {
        padding: 10px 0;
      }

      .slick-arrow {
        z-index: 1;
        padding: 20px;
        background-color: #0D59A8;
        // color: white !important;
      }

      .slick-prev {
        // left: -50px;
        color: white;
      }

      .slick-next {
        // right: -50px;
      }

      .slick-prev:before, .slick-next:before {
        color: white;
        opacity: 1 !important;
        font-size: 25px;
        line-height: none;
        position: absolute;
        top: 10px;
        right: 7px;
      }
    }

    .home-blog-card {
      border: none;
      box-shadow: 0 0 10px #DFDFDF;

      .card-header {
        padding: 0;

        .blog-image {
          width: 100%;
          aspect-ratio: 2/1.4;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .card-body {
        .blog-title {
          font-size: 18px;
          font-weight: 700;
          height: 40px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* number of lines to show */
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

  }

}

.custom-slick {
  .slick-slider {
    width: 95%;
    margin: auto;
  }

  .slick-list {
    padding: 10px 0;
  }

  .slick-arrow {
    z-index: 1;
    padding: 20px;
    background-color: #0D59A8;
    border-radius: 5px;
    // color: white !important;
  }

  .slick-prev {
    // left: -50px;
    color: white;
  }

  .slick-next {
    // right: -50px;
  }

  .slick-prev:before, .slick-next:before {
    color: white;
    opacity: 1 !important;
    font-size: 25px;
    line-height: none;
    position: absolute;
    top: 10px;
    right: 7px;
  }
}
