.about-page {
  .inner-package {
    background-image: linear-gradient(292deg, rgba(20, 69, 128, .81) 0%, rgba(20, 69, 128, .85) 100%), url(https://doctorsoncall.com.np/wp-content/uploads/2020/09/business-03-1.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    color: white !important;

    h1 {
      padding: 5px 0;
    }
  }

  .about-us-section {
    background: #F9F9F9;
  }

  .about-slogan-container {
    height: 400px;
  }

  .about-slogan {
    .slogan-title {
      h1 {
        font-weight: 700;
      }
    }

    .slogan-desc {
      text-align: justify;

      p {
        font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
        font-size: 16px;
        color: #666;
        // -webkit-font-smoothing: antialiased;
        font-weight: 500;
        line-height: 1.7em;

        text-align: justify !important;
      }

    }

  }

  .about-image {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 90%;
      object-fit: cover;
    }
  }

  .drive-us {
    background: rgb(82, 200, 244);
    background: linear-gradient(0deg, rgba(82, 200, 244, 1) 21%, rgba(13, 89, 168, 1) 100%);
    color: white;

    .drive-us-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;

    }

    .about-icons {
      font-size: 22px;
    }

    .drive-us-desc {
      p {
        color: white !important;
      }

      li {
        font-size: 16px;
        line-height: 1.4em;
        margin-bottom: 10px;
        font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;

      }
    }
  }
}

@media screen and (max-width: 740px) {
  .about-slogan-container {
    height: auto !important;
  }
}
