.vendor-list {
  .vendor-card {
    aspect-ratio: 1/0.8;
    width: 100%;
    border: none;
    // border: 1px solid #D3D3D3;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    overflow: hidden;


    .vendor-card-body {
      height: 100%;
      margin: 0;
      padding: 0;

      .img-div {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    // .vendor-card-body {
    //     height: 30%;
    //     padding: 10px;
    // }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
