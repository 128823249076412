/* .Ad-container {
  position: relative;
  right: 7.9rem;
} */
.app-text {
    /* position: relative;
    top: 5rem; */
    padding-top: 100px;
}

.app-text h2 {
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 3rem;
    /* padding-left: 1.5rem; */
}

.app-text p {
    /* padding-left: 1.5rem; */
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 110%;
    color: #000000;
    opacity: 0.7;
}

/* .google-pay{
    padding-left: 1rem;
} */

.img-container {
    width: 100%;
    overflow: hidden;
}

.img-container img {
    width: 98%;
}

@media screen and (max-device-width: 768px) {
    .img-container {
        /* height:550px;
        width:360px; */
        text-align: center;
    }

    .app-text {
        /* position: relative;
        top: 5rem; */
        padding-top: 60px;
    }

    /* .Ad-container{
      height: 40.5rem;
      width: 567px;
      top: -13px;
      position: relative;
      right: 7.5rem;
      object-fit: fill;

    } */
    /* .advertisement-row{
      padding:0;
    } */

}

@media screen and (max-device-width: 425px) {
    .img-container {
        /* width:425px; */
        text-align: center;
    }

    .Ad-container {
        height: 505px;
        width: 380px;
        /* top: 9px;
        position: relative;
        right: 1.5rem;
        object-fit: fill; */
    }

    /* .advertisement .advertisement-row{
      flex-wrap: nowrap;
      align-content: flex-start;
      display: grid;
      grid-column: auto;
    } */
    .app-text {
        /* width: 425px; */
        /* position: relative;*/
        /* top: 2px; */
    }

    .app-text h2 {
        font-size: 38px;
    }

    .app-text p {
        font-size: 17px;
    }
}

@media screen and (max-device-width: 380px) {
    /* .advertisement .advertisement-row{
      width:320px;
    } */
    .img-container {
        /* width:320px;
        height:400px; */
        text-align: center;
    }

    .Ad-container {
        width: 315px;
        height: 350px;
        /* top: 9px;
        position: relative;
        object-fit:cover; */
    }

    .app-text {
        /* width: 320px; */
        padding: 30px 30px 0;
    }

    .app-text h2 {
        font-size: 25px;
    }

    .app-text p {
        font-size: 15px;
    }
}
