@media screen and (max-width: 768px) {
    .button2 {
        display: block;
    }

}

/* ul{

     flex-direction: column;
     display: none;
     position: fixed;
     color: black;
     z-index: 9;
     height: 100vh;
     width: 100%;

  } */
/* ul.expand{
display: block;
}
  ul.collapse{
   display: none;
}

.button2{
   display: block;
   position: relative;
}} */
@media screen and (max-width: 768px) {
    .rating {
        display: none;
    }

}

.button2 {

    color: black;
    z-index: 9;
    height: 100vh;
    width: 100%;


}


