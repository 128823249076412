.grecaptcha-badge {
    visibility: hidden;
}

.footer-bottom {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 80%;
    border: 1px;
    /* border-top-left-radius: 5.5rem;
    border-bottom-right-radius: 5.5rem; */
    background: #57c7f3;
}

/* .bottom {
  border: 1px;
  border-top-left-radius: 5.5rem;
  border-bottom-right-radius: 5.5rem;
  background: #57c7f3;
} */
.bottom-para {
    position: relative;
    top: 1rem;
    letter-spacing: 0.6px;
    color: #ffffff;
    padding-left: 3rem;
    font-weight: 700;
    line-height: 100%;
    font-size: 1.1rem;
}

.para {
    position: relative;
    margin-top: 1rem;
    padding-left: 3rem;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 110%;
    color: #FFF;
    opacity: 0.7;
}

.app-bottom img {
    width: 120px;
    height: 80px;
    margin-top: 0.4rem;
}


@media screen  and (max-device-width: 768px) {
    .bottom {
        height: 7.5rem;
        border-top-left-radius: 5.5rem;
        border-bottom-right-radius: 5.5rem;
        background: #57c7f3;
    }

}

@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
    .bottom-logo {
        width: 50px;
        height: 78px;
    }

    .app-hand {
        display: none;
    }

    .bottom {
        height: 73px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        margin-right: 10px;
        margin-top: 5px;
    }

    .bottom .bottom-para {
        padding-left: 38px;
        font-size: 12px;
        margin-right: 10px;
    }

    .para {
        font-size: 10px;
        padding-left: 36px;
    }

    .footer-btn {
        justify-content: space-between;
        /* padding-bottom: 3px; */
    }

    .cross-btn {

    }
}
