.circular-slider {
  width: 100%;
  position: relative;
  height: 900px;
  box-sizing: border-box;
  padding: 40px;
}

.slide-prev {
  position: absolute;
  transform: translateY(-50%) scale(2);
  left: 0;
  top: 50%;
  cursor: pointer;
}

.slide-next {
  position: absolute;
  transform: translateY(-50%) scale(2);
  right: 0;
  top: 50%;
  cursor: pointer;
}

.slider-right {

}

.circular-slider-item {
  height: 700px;
  background-color: gray;
  border: 1px solid white;
  position: absolute;
  aspect-ratio: 1/1.5;
  transition: all ease-out 0.3s;
  z-index: 0;
}

.circular-slider-item-3, .circular-slider-item-1 {
  transform: translateX(-50%) scale(0.9);
  opacity: 0.8;
  z-index: 1;
}

.circular-slider-item-1 {
  left: 20%;
}

.circular-slider-item-3 {
  left: 80%;
}

// .circular-slider-item-1 {
//    left: 15%;
//    opacity: .4;
//    transform: translateX(-50%);
// }

.circular-slider-item-2 {
  transform: scale(1);
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.circular-slider-item-4, .circular-slider-item-5, .circular-slider-item-6 {
  display: none !important;
}

// .circular-slider-item-2, .circular-slider-item-4 {
//    transform: scale(0.2);
//     opacity: 0.8;
//     aspect-ratio: 1/3;
//     z-index: 1;
// }

// .circular-slider-item-2 {
//     left: 30%;
//     transform: translateX(-50%);

// }

// .circular-slider-item-3 {
//     box-shadow: -2px 5px 33px 6px black;
//     transform: scale(1);
//     opacity: 1;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 2;
// }

// .circular-slider-item-4 {
//     left: 70%;
//     transform: transateX(-50%);
// }

// .circular-slider-item-5 {
//     left: 85%;
//     opacity: .4;
//     transform: translateX(-50%);
// }



