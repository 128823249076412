.top-header-bar {
  position: sticky;
  top: 72px;
  left: 0;
  z-index: 999;
  border: none;

  .breadcrumb-custom {
    margin: 0 !important;
  }

  .card-header {
    border: none;

    .search-bar {
      input {
        border: 1px solid #d3d3d3;
      }

      button {
        background-color: rgb(97, 205, 245);
        color: white;
      }
    }

    .shopping-buttons-icon {
      font-size: 28px;
      // color: rgb(97, 205, 245);
      cursor: pointer;
    }
  }

  .search-group {
    width: 100%;
    margin: auto;
    position: relative;

    .search-content {
      width: 100%;
      background-color: #fff;
      position: absolute;
      z-index: 999;
      padding: 5px 10px;
      margin-top: 5px;
      border: 1px solid #d3d3d3;

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 5px 0;
          cursor: pointer;

          .img-div {
            width: 10%;
            aspect-ratio: 1/1;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &:hover {
            background-color: #F7F7F7;
          }
        }

        hr {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
