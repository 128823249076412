.dr-appointment {
    color: #0259A7;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 100%;
}

.para-text {
    /* margin-top: 0.5rem; */
    color: #000000;
    font-weight: 400;
    /* font-size: 1.2rem; */
    line-height: 110%;
    /* opacity: 0.6; */
}

.view-services {
    border-color: #0259A7 !important;
    color: #0259A7;
    border-radius: 5rem;
    border-width: 2px;
    font-weight: 600;
    padding: 6px 15px;
}

.view-services:hover {
    background-color: #0259A7 !important;
    color: white !important;
    border-color: #0259A7 !important;
}

.top-doctor-slider .doctor-img {
    border: 1px solid transparent;
    height: 15rem;
    object-fit: cover;
    width: auto;
    border-radius: 0.5rem;
    margin: 0.8rem;
    margin-bottom: 0;
}

.doctors-container {
    padding: 0.5rem 1rem 1rem;
}

.doctor-consult {
    border-radius: 8px;
    /* background-color: #0259a7 !important; */
    border: 1px solid;
    font-weight: 600;
    line-height: 20px;
    color: #0259A7;
}

.doctor-consult:hover {
    color: white !important;
    background: #0259A7 !important;
}

.dr-position {
    color: #52C8F4;
    /* font-weight: 600; */
    font-size: 1rem;
    line-height: 28px;
}

.card-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
}

.available-time {
    color: #0259a7;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 20px;
}

.text-heading {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 20px;
    color: #0D59A8 !important;
}

.dr-card {
    border: none;
    box-shadow: 11.0543px 16.5815px 33.163px rgba(2, 89, 167, 0.1);
    border-radius: 8px;
    position: relative;
}

.dr-card .slot-status {
    position: absolute;
    top: 5px;
    right: 20px;
}

.top-doctor-slider .slick-prev {
    z-index: 1;
}

.top-doctor-slider .slick-prev:before, .top-doctor-slider .slick-next:before {
    font-size: 20px;
    opacity: 1;
    color: black;
}

@keyframes expand {
    from {
        transform: scale(1)
    }
    to {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes blink {
    from {
        opacity: 0.5
    }
    to {
        opacity: 1
    }
}


.outer-dot, .inner-dot {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 12px;
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.active .outer-dot {
    animation-name: expand;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.active .blink-dot {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}


.blink-dot {

}
