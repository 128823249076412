.wish-item-container {
    height: 100px;
    border: 2px solid #D3D3D3;
    border-radius: 5px;
}

.wish-item-container .img-div {
    height: 100%;
    width: 100%;
}

.wish-item-container .img-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.txt-highlight {
    font-weight: 600;
}

.wish-item-container button {
    padding: 6px 12px;
    border: none;
}

.add-cart-btn {
    background: #559EFC;
    color: white;
}

.delete-btn {
    background: #DC3545;
    color: white;
}
