.appointment {
    background: linear-gradient(120deg, rgba(192, 223, 246, 0.95) 30%, rgba(87, 199, 243));
}

.appointment .heading p:nth-child(1) {
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: #0259A7;
}

.appointment .heading p:nth-child(2) {
    font-weight: 400;
    line-height: 100%;
    color: #000000;
}

.btn-outline-light {
    border-width: 2px;
}

.speciality-btn {
    border-radius: 37px;
    padding: 6px 15px;
    font-weight: 600;
}

.speciality-btn:hover {
    color: #0259A7;
}

.speciality-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.speciality-icon img {
    /* position: absolute;
    left: 25px;
    top: 25px; */
    width: 60%;
}

.consult-btn {
    color: #0259A7;
    text-decoration: none;
    padding: 4px 10px;
    border: 1px solid;
}

.consult-btn:hover {
    background-color: #0259A7;
    border: 1px solid #0259A7;
    color: white;
}

.department-name {
    font-size: 20px;
    font-weight: 600;
}


.appointment .slick-prev {
    margin-left: 22px;
}

.appointment .slick-next {
    margin-right: 25px;
}

.appointment .slick-list {
    padding-bottom: 10px;
}

.appointment .slick-arrow {
    z-index: 1;
    padding: 20px;
    background-color: #0D59A8;
    border-radius: 5px;
}

.appointment .slick-prev:before,
.appointment .slick-next:before {
    color: white;
    opacity: 1 !important;
    font-size: 25px;
    line-height: none;
    position: absolute;
    top: 10px;
    right: 7px;
}

.appointment .slick-disabled:before {
    color: white;
    opacity: 0.5 !important;
}