.symptom-page {
  .inner-package {
    // background: rgb(2, 89, 167);
    // background: linear-gradient(260deg, rgba(2, 89, 167, 0.8) 0%, rgba(198, 223, 246, 0.8) 77%) url("https://thumbs.dreamstime.com/b/coronavirus-doctor-working-hospital-copy-text-background-banner-doctors-hospitals-fighting-male-protective-suit-mask-178767773.jpg");
    background: linear-gradient(159deg, rgba(2, 89, 167, 0.8) 0%, rgba(198, 223, 246, 0.6) 77%), url(https://st4.depositphotos.com/9999814/22106/i/450/depositphotos_221063800-stock-photo-surgeon-doctor-standing-hospital-medical.jpg);
    background-repeat: no-repeat;
    background-position: top 10em;
    background-size: cover;

    .banner-detail {
      // width: 70%;
      .banner-title {
        h2 {
          font-weight: 800;
          color: white;
          text-align: left;
          line-height: 28px;
        }

        h4 {
          font-size: 18px;
          color: white;
          text-align: left;
        }

      }

      .banner-btn {
        text-align: left;
      }
    }
  }

  .symptoms {
    background-color: #FDFBF7;

    .symptom-items {
      max-height: 400px;
      overflow-y: scroll;
    }
  }

  .dep-item-card {
    padding: 0;
    margin: 0;

    .card-header {
      background-color: inherit;

      .dep-item-title {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .card-body {
      .dep-symptoms-item {
        text-align: center;
        border: 1px solid #DEE2E6;
        padding: 10px 0;
        font-weight: 600;

        &.selected {
          border: 1px solid #75D3F6;
          color: #75D3F6;
        }
      }
    }
  }

}

.symptom-container {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  .badge-container {
    span {
      top: 10px;
      right: 10px;
    }
  }
}
