.service-filter-page {
  & * {
    font-family: "Open Sans", sans-serif !important;
    // font-size: 14px;
  }

  & > .container {
    & > .row {
      border-top: 1px solid #DFE2EA;
    }
  }

  .service-results-container {
    border-bottom: 1px solid #DFE2EA;
  }


  .filter-bar {
    border-right: 1px solid #DFE2EA;

    .filter-section {
      margin: 25px 0;

      .filter-title {
        margin-bottom: 10px;
      }

      .filter-items {
        margin: 5px 0;

        .btn {
          border-radius: 3px;
          padding: 5px 15px;
        }
      }
    }
  }
}

.service-item-card {
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0px 3px 6px #3c3c3c29;
  transition: box-shadow 200ms;
  transform: translateZ(0);
  will-change: transform;


  .service-item-img {
    aspect-ratio: 1.2/1;
    overflow: hidden;
    // border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .service-details {
    padding: 0 10px 5px;

    .service-name {
      // font:16px "Open Sans, sans-serif" !important;

      box-sizing: border-box;
      // font-weight: 550;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      /* fallback */
      height: 34px;
      /* fallback */
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .service-price {
      font-size: 26px;
      font-weight: 700;
      color: #0D59A8;
    }

    .btn-success {
      width: 100%;
      border-radius: 3px;
      background: #52C8F4;
      border: none;
    }
  }

  &:hover {
    border-radius: 8px;
    // -webkit-font-smoothing: subpixel-antialiased;
    // transform: translateZ(0);
    // backface-visibility: hidden;
    box-shadow: 0px 3px 20px #3c3c3c29;
    cursor: pointer;
  }
}
